import layout from '../components/layout/layout.vue'
//定义业务页面
const routeMap = [{
        path: "/",
        component: layout,
        children: [{
                path: "/index",
                name: "总控大屏",
                component: () =>
                    import("@/views/index/index.vue"),
            },
            {
                path: "/personManage",
                name: "客流大数据",
                component: () =>
                    import("@/views/personManage/index.vue"),
            },
            {
                path: "/deviceManage",
                name: "车辆大数据",
                component: () =>
                    import("@/views/deviceManage/index.vue"),
            },
            {
                path: "/monitorManage",
                name: "监控大数据",
                component: () =>
                    import("@/views/monitorManage/index.vue"),
            }
        ]
    },
    //通用界面注册
    // {
    //     path: '/login',
    //     name: '登录',
    //     meta: {
    //         title: '登录页面'
    //     },
    //     component: () =>
    //         import("@/views/public/login.vue"),
    // }
]
export default routeMap