import instance from './request'
// import { Loading } from 'element-ui'
//加载实例

const Http = {}     //包含所有请求

// let loadingInstance
const modulesFiles = require.context('./modules',false,/.js$/)

const apiList = modulesFiles.keys().reduce((modules,modulePath)=>{
  // const moduleName = modulePath.replace(/^.\/(.*)\.js/, '$1')
  const value = modulesFiles(modulePath)
  //所有请求config 合并成一个对象
  modules = {...modules,...value.default}  
	return modules
},{})

 
for(let key in apiList){
  let api = apiList[key]    //有url,method属性
  Http[key] = async (
    params,//请求参数
    config={} //axios配置参数
  )=>{
    let response = {}   //返回值
    // loadingInstance = Loading.service({
		// 	text: '数据加载中...',
		// 	fullscreen: true,
		// 	background: 'rgba(0,0,0,0.5)',
		// })
    config.method = api.method
    config.url = api.url
    if(api.method == 'PUT'||api.method == 'POST'){
      config.data = params
    }
    if(api.method == 'GET'||api.method == 'DELETE'){
      config.params = params
    }
    
    try {
      response = await instance(config)
      
    } catch (error) {
      response = error

    }
    // loadingInstance.close()
    return response
  }
}

export default Http