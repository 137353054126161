<template>
  <div class="pagination">
    <el-pagination
      v-if="pageShow"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :small="true"
      :current-page="current"
      :page-sizes="sizeList"
      :page-size="pageSize"
      :pager-count="5"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    pageShow: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 1000,
    },
    current: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    sizeList: {
      type: Array,
      default: () => {
        return [14, 35, 49];
      },
    },
  },
  watch: {},
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff !important;
}
</style>
<style lang="scss">
.pagination {
  li {
    &.active {
      color: #fff !important;
    }
  }
}
</style>