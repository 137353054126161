<template>
  <el-header class="header flex">
    <div class="headerCon">
      <div class="itemCon">
        <div :class="curTab == 1 ? 'leftItemSelect' : 'leftItem'" @click="taptab(1)">总控大屏</div>
        <div :class="curTab == 2 ? 'leftItemSelect' : 'leftItem'" @click="taptab(2)">客流大数据</div>
      </div>
      <div class="centertCon">
        <img style="width: 70%;height: 54px;margin-top: -20px;" src="../../assets/image/titleimgtext.png" alt="" />
      </div>
      <div class="itemCon">
        <div :class="curTab == 3 ? 'rightItemSelect' : 'rightItem'" @click="taptab(3)">车辆大数据</div>
        <div :class="curTab == 4 ? 'rightItemSelect' : 'rightItem'" @click="taptab(4)">监控大数据</div>
      </div>
    </div>
  </el-header>
</template>
<script>
export default {
  components: {},
  props: {},
  created() {
    let curPath = this.$route.path;
    switch (curPath) {
      case "/index":
        this.curTab = 1;
        break;
      case "/personManage":
        this.curTab = 2;
        break;
      case "/deviceManage":
        this.curTab = 3;
        break;
      case "/monitorManage":
        this.curTab = 4;
        break;
      default:
        break;
    }
  },
  computed: {},
  mounted() { },
  data() {
    return {
      curTab: 1,
    };
  },
  watch: {},
  methods: {
    taptab(index) {

      this.curTab = index;
      let path = "/index"
      switch (index) {
        case 1:
          path = "/index"
          break;
        case 2:
          path = "/personManage"
          break;
        case 3:
          path = "/deviceManage"
          break;
        case 4:
          path = "/monitorManage"
          break;
        default:
          break;
      }
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3077ad;
  width: 100%;
  height: 100%;


  .titleimg {
    position: absolute;
    top: -10px;
    left: -50px;
    width: 100%;
    // width: 39%;
  }

  .headerCon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../../assets/image/titleimg.png");
    background-position: left top;
    background-size: 100% 100%;
    padding: 0px 24px;

    .centertCon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .itemCon {
      width: 27%;
      display: flex
    }

    .item {
      width: 50%;
      height: 54px;
      background-position: left top;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-size: 26px;
      font-weight: 400;
      color: #02C8EB;
      cursor: pointer;
    }

    .leftItem {
      @extend .item;
      background-image: url("../../assets/image/titleimgleft.png");
      color: #02C8EB;
    }

    .leftItemSelect {
      @extend .item;
      background-image: url("../../assets/image/titleimgleftS.png");
      color: #ffffff;
    }

    .rightItem {
      @extend .item;
      background-image: url("../../assets/image/titleimgright.png");
      color: #02C8EB;
    }

    .rightItemSelect {
      @extend .item;
      background-image: url("../../assets/image/titleimgrightS.png");
      color: #ffffff;
    }

    img {
      width: 256px;
      height: 54px;
    }
  }

  @media (min-width: 0px) {
    font-size: 10px;
  }

  @media (min-width: 900px) {
    font-size: 16px;
  }

  @media (min-width: 1500px) {
    font-size: 24px;
  }
}
</style>