import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'video.js/dist/video-js.css'
import * as echarts from 'echarts';
import Apppage from '@/components/pagination/AppPagination'



import api from '@/api'

Vue.use(ElementUI);
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.component('app-page', Apppage) //分页组件

Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')