<template>
  <el-container class="home-container">
    <el-header>
      <Header></Header>
    </el-header>
    <el-container>
      <!-- <el-aside class="aside" :width="isCollapse ? '64px' : '220px'">
        <Menu :activePath="activePath"></Menu>
      </el-aside> -->
      <el-main class="main">
        <transition :name="direction">
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Header from "./header.vue";
import transJs from "@/utils/transJs.js";
export default {
  created() {
    console.log("进入layout");
    this.activePath = this.$route.path;
  },
  mounted() {
    transJs.$on("collapseMenu", (info) => {
      this.isCollapse = info;
    });
  },
  beforeDestroy() { },
  components: {
    Header,
  },
  data() {
    return {
      activePath: "",
      isCollapse: false,
      indexPagelist: ["/index", "/personManage", "/deviceManage", "/monitorManage"],
      direction: "fadeInRight",
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.activePath = to.path;
      const toIndex = this.indexPagelist.indexOf(to.path); // 进入下标
      const fromIndex = this.indexPagelist.indexOf(from.path); // 离开下标
      if (toIndex > -1) {
        // 如果下标存在
        if (toIndex < fromIndex) {
          // 如果进入的下标小于离开的下标，那么是左滑
          this.direction = "fadeInLeft";
        } else {
          this.direction = "fadeInRight"; // 如果进入的下标大于离开的下标，那么是右滑
        }
      }
      console.log(this.direction);
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.home-container {
  display: flex;
  height: 100%;
  overflow: hidden;

  .el-header {
    width: 100%;
    height: 113px !important;
    background: #05172f;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
    padding: 0px;
  }

  .el-container {
    background-color: #15172d;
    width: 100%;

    .main {
      padding: 0;
      // border-radius: 20px;
      // margin: 20px;
      height: calc(100vh - 113px);
      overflow: hidden;
      box-shadow: 0px 5px 5px 5px rgb(0 0 0 / 15%);
      background: #06152a;
    }
  }
}

::-webkit-scrollbar-track {
  background-color: #724741;
}

/*base code*/
.fadeInLeft-enter-active {
  animation: fadeInLeft 0.3s;
}

.fadeInLeft-leave-active {}

/*the animation definition*/
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    // -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  // 50% {
  //   // -webkit-transform: translate3d(1%, 0, 0);
  //   transform: translate3d(1%, 0, 0);
  // }
  // 75% {
  //   // -webkit-transform: translate3d(-1%, 0, 0);
  //   transform: translate3d(1%, 0, 0);
  // }
  100% {
    opacity: 1;
    // -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/*base code*/
.fadeInRight-enter-active {
  animation: fadeInRight 0.3s;
}

.fadeInRight-leave-active {}

/*the animation definition*/
@keyframes fadeInRight {
  0% {
    opacity: 0;
    // -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    // transform: none;
  }

  100% {
    opacity: 1;
    // -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
</style>