import Vue from 'vue'
import VueRouter from 'vue-router'
import routeMap from './route'

// import {
//   Message
// } from 'element-ui'
Vue.use(VueRouter)
// let token = ""
// let whiteList = []
const routes = []
const router = new VueRouter({
  routes: [...routes, ...routeMap]
})
router.beforeEach((to, from, next) => {
  document.title = '德兴市智慧旅游大数据平台'
  // console.log(to.path, from.path);
  // token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : ""
  next()
  if (to.path === "/") { //去首页
    next({
      path: '/index'
    })
  } else {
    next()
    return
  }
  // console.log('token', token);
  // if (token) {
  //   next()
  //   if (to.path === "/") { //去首页
  //     next({
  //       path: '/index'
  //     })
  //   } else {
  //     next()
  //     return
  //   }
  // } else { //没有登录token的时候
  //   if (to.path === '/login') { //判断路由跳转是否为登录流程
  //     next()
  //   } else {
  //     next({
  //       path: '/login'
  //     })
  //   }
  // }
})

export default router