import axios from 'axios'
import router from '@/router'
import { Message,  MessageBox } from 'element-ui'

let  baseURL
if (process.env.NODE_ENV == 'development') {
	baseURL = '/api'
} else {
	baseURL = 'https://village.dexing.leppysoft.com/api'
}

const instance = axios.create({
	baseURL: baseURL,
	timeout: 60000,
})



/**
 * 请求拦截
 */
instance.interceptors.request.use(
	(config) => {
		// 这里判断sessionStorage里面是否存在token，如果有则在请求头里面设置
		

		// if (sessionStorage.token) {
		// 	config.headers.Authorization = sessionStorage.token
		// } else {
			
			
		// }

		return config
	},
	(err) => {
		Message({
			type: 'error',
			message: '请求错误,请稍后重试',
		})
		return Promise.reject(err)
	}
)

/**
 * 响应拦截
 */
instance.interceptors.response.use(
	(response) => {
		// console.log(response)

		if (response.data.code != 0) {
			Message({
				type: 'error',
				message: response.data.msg ,
			})
			return Promise.reject(response.data)
		} else {
			return response.data
		}
		// return response.data
	},
	(error) => {

		console.log(error)
		if (error.response.status == '401') {
			sessionStorage.removeItem('token')
			MessageBox.confirm('您还未登录或者登录失效,请重新登录', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'error',
			})
				.then(() => {
					router.replace({
						name: 'login',
					})
				})
				.catch(() => {
					router.replace({
						name: 'login',
					})
				})
		} else if (error.response.status == '500') {
			Message({
				type: 'error',
				message: '服务器错误,请与管理员联系',
			})
		} else {
			Message({
				type: 'error',
				message: error.response.data.msg,
			})
		}

		return Promise.reject(error)
	}
)

export default instance
