//总控大屏
const CONTROL_API = {
	//获取防疫数据
	getEpidemicData: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/todayEpidemicPreventionData',
	},
	//获取客流数据
	getPassengerFlowData: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/passengerFlow',
	},
	//获取当前景区数据
	getScenicData: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/scenic',
	},
	//获取景区客流时段分析
	getScenicPassengerFlowPeriod: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/scenicPassengerFlowPeriod',
	},
}


export default CONTROL_API