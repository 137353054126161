//客流大数据
const PASSENGER_API = {
	//客流量统计
	getPassengerStatistics: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/historicalPassengerFlow',
	},
	//游客数
	getPassengerCount: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/passengerCount',
	},
	//今日各景区客流量统计
	getTodayScenicPassengerFlow: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/todayScenicPassengerFlow',
	},
	//今日客流分时段统计
	getTodayPassengerFlowPeriod: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/todayPassengerFlowPeriod',
	},
	//近七天客流统计
	getSevenDaysPassengerFlow: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/sevenDaysPassengerFlow',
	},
	//景区拥堵情况分析
	getScenicCongestion: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/scenicCongestion',
	},
	//历史客流排行榜
	getHistoryScenicPassengerFlow: {
		method: 'GET',
		url: '/digital-village/dx/bigScreen/historyScenicPassengerFlow',
	},
}

export default PASSENGER_API